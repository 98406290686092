// "use client"

// import { useState, useEffect, useCallback } from "react"
// import { AlertTriangle, ChevronLeft, ChevronRight, Moon, Sun } from "lucide-react"
// import { Progress } from "@/components/ui/progress"

// export default function QuestionList() {
//   const [questions, setQuestions] = useState([])
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState(null)
//   const [pagination, setPagination] = useState({
//     count: 0,
//     next: null,
//     previous: null,
//     currentPage: 1,
//   })
//   const [selectedAnswers, setSelectedAnswers] = useState({})
//   const [timeRemaining, setTimeRemaining] = useState(3600) // 1 hour in seconds
//   const [isDarkMode, setIsDarkMode] = useState(false)

//   // Base URL for the API
//   const baseUrl = "https://nec.geoneer.com.np/api/exam/questions/PSC-S/GA/G1/all-questions/"

//   const fetchQuestions = useCallback(async (url) => {
//     setLoading(true)
//     try {
//       const response = await fetch(url)
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`)
//       }
//       const data = await response.json()
//       setQuestions(data.results)
//       setPagination({
//         count: data.count,
//         next: data.next,
//         previous: data.previous,
//         currentPage: getPageNumberFromUrl(url) || 1,
//       })
//     } catch (err) {
//       setError(err.message)
//     } finally {
//       setLoading(false)
//     }
//   }, [])

//   // Extract page number from URL
//   const getPageNumberFromUrl = (url) => {
//     if (!url) return 1
//     const match = url.match(/page=(\d+)/)
//     return match ? Number.parseInt(match[1]) : 1
//   }

//   // Handle page navigation
//   const handlePageChange = (url) => {
//     if (url) {
//       fetchQuestions(url)
//     }
//   }

//   // Handle option selection
//   const handleOptionClick = (itemId, option) => {
//     if (!selectedAnswers[itemId]) {
//       setSelectedAnswers((prevState) => ({
//         ...prevState,
//         [itemId]: option.toString(),
//       }))
//       // Save progress to local storage
//       localStorage.setItem(
//         "quizProgress",
//         JSON.stringify({
//           ...selectedAnswers,
//           [itemId]: option.toString(),
//         }),
//       )
//     }
//   }

//   // Get option class name based on selection state
//   const getOptionClassName = (item, option) => {
//     const selectedOption = selectedAnswers[item.id]

//     const baseClass = "flex items-center p-3 rounded-md border cursor-pointer transition-colors"

//     if (selectedOption === option.toString()) {
//       if (selectedOption === item.correctOpt.toString()) {
//         return `${baseClass} bg-green-50 border-green-200 text-green-700 dark:bg-green-900 dark:border-green-700 dark:text-green-100`
//       } else {
//         return `${baseClass} bg-red-50 border-red-200 text-red-700 dark:bg-red-900 dark:border-red-700 dark:text-red-100`
//       }
//     } else if (selectedOption && option.toString() === item.correctOpt.toString()) {
//       return `${baseClass} bg-green-50 border-green-200 text-green-700 dark:bg-green-900 dark:border-green-700 dark:text-green-100`
//     } else if (selectedOption) {
//       return `${baseClass} bg-gray-50 border-gray-200 text-gray-400 cursor-not-allowed dark:bg-gray-800 dark:border-gray-700 dark:text-gray-500`
//     } else {
//       return `${baseClass} bg-gray-50 border-gray-200 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`
//     }
//   }

//   // Handle reporting a question
//   const handleReportQuestion = (questionId) => {
//     // In a real app, you would open a modal here
//     alert(`Reporting question ${questionId}. This functionality would open a modal in a complete implementation.`)
//   }

//   // Toggle dark mode
//   const toggleDarkMode = () => {
//     setIsDarkMode(!isDarkMode)
//     document.documentElement.classList.toggle("dark")
//   }

//   // Timer effect
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeRemaining((prevTime) => {
//         if (prevTime <= 0) {
//           clearInterval(timer)
//           return 0
//         }
//         return prevTime - 1
//       })
//     }, 1000)

//     return () => clearInterval(timer)
//   }, [])

//   // Initial data fetch and load saved progress
//   useEffect(() => {
//     fetchQuestions(`${baseUrl}?page=1`)
//     const savedProgress = localStorage.getItem("quizProgress")
//     if (savedProgress) {
//       setSelectedAnswers(JSON.parse(savedProgress))
//     }
//   }, [fetchQuestions])

//   // Keyboard navigation
//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (e.key === "ArrowLeft" && pagination.previous) {
//         handlePageChange(pagination.previous)
//       } else if (e.key === "ArrowRight" && pagination.next) {
//         handlePageChange(pagination.next)
//       }
//     }

//     window.addEventListener("keydown", handleKeyDown)
//     return () => window.removeEventListener("keydown", handleKeyDown)
//   }, [pagination]) // Removed handlePageChange from dependencies

//   if (loading && questions.length === 0) {
//     return (
//       <div className="flex justify-center items-center min-h-[400px]">
//         <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
//       </div>
//     )
//   }

//   if (error) {
//     return (
//       <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md dark:bg-red-900 dark:border-red-700 dark:text-red-100">
//         <p>Error loading questions: {error}</p>
//         <button
//           onClick={() => fetchQuestions(`${baseUrl}?page=1`)}
//           className="mt-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-600"
//         >
//           Retry
//         </button>
//       </div>
//     )
//   }

//   const totalPages = Math.ceil(pagination.count / 10) // Assuming 10 items per page
//   const attendedQuestionsCount = Object.keys(selectedAnswers).length
//   const progressPercentage = (attendedQuestionsCount / pagination.count) * 100

//   return (
//     <div className={`w-full max-w-4xl mx-auto ${isDarkMode ? "dark" : ""}`}>
//       <div className="flex justify-between items-center mb-6">
//         <h1 className="text-2xl font-bold dark:text-white">Exam Questions</h1>
//         <div className="flex items-center space-x-4">
//           <div className="bg-blue-50 text-blue-700 px-4 py-2 rounded-md font-medium dark:bg-blue-900 dark:text-blue-100">
//             {attendedQuestionsCount} / {pagination.count} Questions Attended
//           </div>
//           <button onClick={toggleDarkMode} className="p-2 rounded-full bg-gray-200 dark:bg-gray-700">
//             {isDarkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
//           </button>
//         </div>
//       </div>

//       <div className="mb-4 flex justify-between items-center">
//         <div className="text-lg font-semibold dark:text-white">
//           Time Remaining: {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, "0")}
//         </div>
//         <Progress value={progressPercentage} className="w-1/2" />
//       </div>

//       <div className="space-y-8">
//         {questions.map((item, index) => {
//           const questionNumber = (pagination.currentPage - 1) * 10 + index + 1
//           return (
//             <div
//               key={item.id}
//               className="bg-white rounded-lg shadow-md p-6 border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
//             >
//               <div className="flex justify-between items-start mb-4">
//                 <div className="flex items-start gap-2">
//                   <span className="bg-gray-100 text-gray-700 font-medium px-2 py-1 rounded-md whitespace-nowrap dark:bg-gray-700 dark:text-gray-300">
//                     Q {questionNumber}
//                   </span>
//                   <h3 className="text-lg font-medium dark:text-white">{item.question}</h3>
//                 </div>
//                 <button
//                   onClick={() => handleReportQuestion(item.id)}
//                   className="ml-2 px-2 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded flex items-center dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white"
//                 >
//                   <AlertTriangle className="w-4 h-4 mr-1" />
//                   Report
//                 </button>
//               </div>

//               <div className="grid gap-3 mt-4">
//                 {[1, 2, 3, 4].map((optNum) => (
//                   <div
//                     key={optNum}
//                     onClick={() => handleOptionClick(item.id, optNum)}
//                     className={getOptionClassName(item, optNum)}
//                   >
//                     <div className="flex items-center justify-center w-8 h-8 rounded-full mr-3 bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300">
//                       {String.fromCharCode(64 + optNum)}
//                     </div>
//                     <span className="dark:text-white">{item[`option${optNum}`]}</span>
//                     {selectedAnswers[item.id] && optNum === Number.parseInt(item.correctOpt) && (
//                       <span className="ml-auto text-green-600 font-medium dark:text-green-400">Correct Answer</span>
//                     )}
//                   </div>
//                 ))}
//               </div>

//               {selectedAnswers[item.id] && (
//                 <div className="mt-4 p-3 bg-blue-50 border border-blue-100 rounded-md dark:bg-blue-900 dark:border-blue-800">
//                   <p className="text-sm font-medium text-blue-800 dark:text-blue-200">Explanation:</p>
//                   <p className="text-sm text-blue-700 dark:text-blue-300">
//                     {item.explanation && item.explanation !== "N/A" && item.explanation !== "n/a"
//                       ? item.explanation
//                       : "No explanation provided."}
//                   </p>
//                 </div>
//               )}

//               <div className="mt-3 text-sm text-gray-500 dark:text-gray-400">Weightage: {item.weightage}</div>
//             </div>
//           )
//         })}
//       </div>

//       {/* Pagination */}
//       <div className="flex items-center justify-between mt-8 mb-4">
//         <div className="text-sm text-gray-700 dark:text-gray-300">
//           Showing page {pagination.currentPage} of {totalPages}
//         </div>

//         <div className="flex gap-2">
//           <button
//             onClick={() => handlePageChange(pagination.previous)}
//             disabled={!pagination.previous}
//             className={`flex items-center px-4 py-2 rounded-md ${
//               pagination.previous
//                 ? "bg-primary text-white hover:bg-primary/90 dark:bg-primary/80 dark:hover:bg-primary/70"
//                 : "bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500"
//             }`}
//           >
//             <ChevronLeft className="w-4 h-4 mr-1" />
//             Previous
//           </button>

//           <button
//             onClick={() => handlePageChange(pagination.next)}
//             disabled={!pagination.next}
//             className={`flex items-center px-4 py-2 rounded-md ${
//               pagination.next
//                 ? "bg-primary text-white hover:bg-primary/90 dark:bg-primary/80 dark:hover:bg-primary/70"
//                 : "bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500"
//             }`}
//           >
//             Next
//             <ChevronRight className="w-4 h-4 ml-1" />
//           </button>
//         </div>
//       </div>

//       {/* Page number navigation */}
//       <div className="flex justify-center gap-1 mt-4 mb-8 flex-wrap">
//         {Array.from({ length: Math.min(totalPages, 10) }, (_, i) => {
//           const pageNum = i + 1
//           const isCurrentPage = pageNum === pagination.currentPage

//           return (
//             <button
//               key={pageNum}
//               onClick={() => fetchQuestions(`${baseUrl}?page=${pageNum}`)}
//               className={`w-10 h-10 flex items-center justify-center rounded-md ${
//                 isCurrentPage
//                   ? "bg-primary text-white dark:bg-primary/80"
//                   : "bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-300"
//               }`}
//             >
//               {pageNum}
//             </button>
//           )
//         })}

//         {totalPages > 10 && (
//           <>
//             <span className="w-10 h-10 flex items-center justify-center dark:text-white">...</span>
//             <button
//               onClick={() => fetchQuestions(`${baseUrl}?page=${totalPages}`)}
//               className="w-10 h-10 flex items-center justify-center rounded-md bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-300"
//             >
//               {totalPages}
//             </button>
//           </>
//         )}
//       </div>
//     </div>
//   )
// }

// "use client"

// import { useState, useEffect } from "react"
// import { ChevronLeft, ChevronRight, Moon, Sun, AlertTriangle } from "lucide-react"
// import parse from "html-react-parser"

// export default function QuestionList() {
//   const [questions, setQuestions] = useState([])
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState(null)
//   const [pagination, setPagination] = useState({ next: null, previous: null, currentPage: 1 })
//   const [selectedAnswers, setSelectedAnswers] = useState({})
//   const [showExplanation, setShowExplanation] = useState({})
//   const [isDarkMode, setIsDarkMode] = useState(
//     typeof window !== "undefined" && localStorage.getItem("darkMode") === "true"
//   )

//   const baseUrl = "https://nec.geoneer.com.np/api/exam/questions/PSC-S/GA/G1/all-questions/"

//   useEffect(() => {
//     fetchQuestions(`${baseUrl}?page=1`)
//   }, [])

//   const fetchQuestions = async (url) => {
//     setLoading(true)
//     try {
//       const response = await fetch(url)
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`)
//       }
//       const data = await response.json()
//       setQuestions(data.results)
//       setPagination({
//         next: data.next,
//         previous: data.previous,
//         currentPage: getPageNumberFromUrl(url) || 1,
//       })
//     } catch (err) {
//       setError(err.message)
//     } finally {
//       setLoading(false)
//     }
//   }

//   const getPageNumberFromUrl = (url) => {
//     if (!url) return 1
//     const match = url.match(/page=(\d+)/)
//     return match ? Number.parseInt(match[1]) : 1
//   }

//   const handlePageChange = (url) => {
//     if (url) {
//       fetchQuestions(url)
//     }
//   }

//   const handleOptionClick = (questionId, option) => {
//     setSelectedAnswers((prev) => ({ ...prev, [questionId]: option }))
//     setShowExplanation((prev) => ({ ...prev, [questionId]: true }))
//   }

//   const toggleDarkMode = () => {
//     setIsDarkMode(!isDarkMode)
//     localStorage.setItem("darkMode", !isDarkMode)
//     document.documentElement.classList.toggle("dark")
//   }

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center min-h-[400px]">
//         <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
//       </div>
//     )
//   }

//   if (error) {
//     return (
//       <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md dark:bg-red-900 dark:border-red-700 dark:text-red-100">
//         <p>Error loading questions: {error}</p>
//       </div>
//     )
//   }

//   return (
//     <div className={`w-full max-w-4xl mx-auto ${isDarkMode ? "dark" : ""}`}>
//       <div className="flex justify-between items-center mb-6">
//         <h1 className="text-2xl font-bold dark:text-white">Exam Questions</h1>
//         <button onClick={toggleDarkMode} className="p-2 rounded-md bg-gray-200 dark:bg-gray-800">
//           {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
//         </button>
//       </div>

//       {questions.map((item) => (
//         <div key={item.id} className="mb-6 p-4 border rounded-md dark:border-gray-700 bg-white dark:bg-gray-900">
//           <p className="font-medium text-lg dark:text-gray-200">{parse(item.question)}</p>
//           <div className="mt-3 space-y-2">
//             {[item.opt1, item.opt2, item.opt3, item.opt4].map((option, index) => (
//               <button
//                 key={index}
//                 onClick={() => handleOptionClick(item.id, option)}
//                 className={`block w-full text-left px-4 py-2 rounded-md border transition-all duration-200 ease-in-out shadow-sm
//                   ${selectedAnswers[item.id] === option ?
//                     (option === item.correctOpt ? "bg-green-100 border-green-500 text-green-700 dark:bg-green-800 dark:border-green-400 dark:text-green-100"
//                       : "bg-red-100 border-red-500 text-red-700 dark:bg-red-800 dark:border-red-400 dark:text-red-100")
//                     : "bg-gray-50 border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700"}`}
//               >
//                 {parse(option)}
//               </button>
//             ))}
//           </div>
//           {showExplanation[item.id] && (
//             <div className="mt-3 p-3 bg-blue-50 border border-blue-300 rounded-md dark:bg-blue-900 dark:border-blue-700 flex items-start">
//               <AlertTriangle size={18} className="text-blue-700 dark:text-blue-200 mr-2" />
//               <p className="text-blue-700 dark:text-blue-200">Explanation: {parse(item.explanation)}</p>
//             </div>
//           )}
//         </div>
//       ))}

//       <div className="flex justify-between mt-6">
//         <button
//           onClick={() => handlePageChange(pagination.previous)}
//           disabled={!pagination.previous}
//           className={`px-4 py-2 rounded-md flex items-center transition-all
//             ${pagination.previous ? "bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700"
//             : "bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-900 dark:text-gray-500"}`}
//         >
//           <ChevronLeft size={18} className="mr-2" />
//           Previous
//         </button>
//         <span className="text-gray-700 dark:text-gray-200">Page {pagination.currentPage}</span>
//         <button
//           onClick={() => handlePageChange(pagination.next)}
//           disabled={!pagination.next}
//           className={`px-4 py-2 rounded-md flex items-center transition-all
//             ${pagination.next ? "bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700"
//             : "bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-900 dark:text-gray-500"}`}
//         >
//           Next
//           <ChevronRight size={18} className="ml-2" />
//         </button>
//       </div>
//     </div>
//   )
// }


"use client"

import { useState, useEffect } from "react"
import axios from "axios"
import { useLocation } from "react-router-dom"
// import AuthContext from "../../context/AuthContext"
import ReportQuestion from "./ReportQuestion"
import { AlertTriangle } from "lucide-react"


const QuestionList = () => {
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedAnswers, setSelectedAnswers] = useState({})
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [reportQuestionId, setReportQuestionId] = useState(null)



  const location = useLocation()

  const {  courseCode,  chapterCode,  subChapterCode } = location.state || {}



  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get(
          `https://nec.geoneer.com.np/api/exam/questions/${courseCode}/${chapterCode}/${subChapterCode}/all-questions/?page=${currentPage}`,
        )

        setQuestions(response.data.results)
        setTotalPages(Math.ceil(response.data.count / 10)) // Assuming 10 items per page
        setIsLoading(false)
      } catch (err) {
        setError(err.message)
        setIsLoading(false)
      }
    }

    fetchQuestions()
  }, [courseCode, chapterCode, subChapterCode,currentPage])

  const parseHtml = (htmlString) => {
    if (!htmlString) return ""
    const div = document.createElement("div")
    div.innerHTML = htmlString
    return div.textContent || div.innerText || ""
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      setSelectedAnswers({})
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      setSelectedAnswers({})
    }
  }

  const handleOptionClick = (questionId, selectedOption) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: selectedOption,
    }))
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500">Error: {error}</div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-500">Loading questions...</div>
      </div>
    )
  }

  const startQuestionNumber = (currentPage - 1) * 10 + 1



  const handleReportQuestion = (questionId) => {
    setReportQuestionId(questionId)
    setReportModalOpen(true)
  }

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-2xl font-bold mb-6">All Questions</h1>

      <div className="space-y-6">
        {questions.map((question, index) => (
          <div key={question.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-6">
              <div className="flex items-start gap-2 mb-4">
                <span className="font-medium text-gray-700 whitespace-nowrap">Q{startQuestionNumber + index}:</span>
                <span className="text-gray-800">{parseHtml(question.question)}</span>
                <button
                    onClick={() => handleReportQuestion(question.id)}
                    className="ml-2 px-2 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded"
                  >

                    <AlertTriangle className="w-4 h-4 mr-1 inline" />
                    Report
                  </button>
                  {console.log("question id is ",question.id)}
              </div>

              <div className="space-y-3 pl-6">
                {[1, 2, 3, 4].map((optNum) => (
                  <div
                    key={optNum}
                    onClick={() => handleOptionClick(question.id, optNum)}
                    className={`p-3 rounded-md border cursor-pointer ${
                      selectedAnswers[question.id] === optNum
                        ? question.correctOpt === optNum
                          ? "border-green-500 bg-green-500"
                          : "border-red-500 bg-red-500"
                        : question.correctOpt === optNum && selectedAnswers[question.id]
                          ? "border-green-500 bg-green-500"
                          : "border-gray-200 hover:bg-gray-50"
                    }`}
                  >
                    <div className="flex items-start gap-2">
                      {/* <span className="font-medium text-gray-700">{optNum}.</span> */}
                      <span className="text-gray-800">{parseHtml(question[`option${optNum}`])}</span>
                    </div>
                  </div>
                ))}
              </div>

              {selectedAnswers[question.id] && question.explanation && question.explanation !== "N/A" && (
                <div className="mt-4 pt-4 border-t border-gray-200">
                  <p className="text-sm font-medium text-gray-700">Explanation:</p>
                  <p className="text-sm text-gray-600 mt-1">{parseHtml(question.explanation)}</p>
                </div>
              )}
            </div>
          </div>
        ))}
                <ReportQuestion
          questionId={reportQuestionId}
          isOpen={reportModalOpen}
          onClose={() => setReportModalOpen(false)}
        />
      </div>

      {totalPages > 1 && (
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md ${
              currentPage === 1
                ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-md ${
              currentPage === totalPages
                ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            Next
          </button>
        </div>
      )}
    </div>
  )
}

export default QuestionList





